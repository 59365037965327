/* Custom file news-corporate CSS */

/* Section news slide */
#news .bg_image--31 {
    min-height: 70vh;
    background-color: #ff0066;
    background-image: url(/assets/images/bg/fondo-imagen-corporate-info.jpg) !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100vw 100%;
}
#news .container-rotating-text {
	padding-top: 5vw;
}
#news .container .container-rotating-text h3.last-title {
	font-size: 35px;
}
#news h3.last-title {
	margin-top: 35px;
}
/* ./news slide */


/* Section video work-cafe */
#video-work-cafe .container-video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
#video-work-cafe .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;/* Then style the iframe to fit in the container div with full height and width */
    height: 100%;
}
#video-work-cafe .video {
    width: 100%; 
    height: 100%; 
    margin: 0; 
    padding: 0;
    border-radius: 0px !important;
}
/* ./video */


/* Section atomic culture */
#atomic-culture .container.culture {
    padding: 0;
    margin: 0 auto;
    max-width: calc(100vw - 50px);
}
#atomic-culture .thumbnail-paralax {
	background-color: #101010;
	background-image: url(/assets/images/blog/imagen-cultura-identidad.jpg);
	background-position: left 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 46vw;
}
#atomic-culture .about-area .area-top {
	padding: 0;
}
#atomic-culture .about-area .area-top .float-box-bottom {
	bottom: -78px;
}
#atomic-culture .about-area .area-top .float-box-bottom h3 {
	font-size: 30px;
}
#atomic-culture .about-area .area-top .float-box-bottom h4 {
	font-size: 25px;
}
#atomic-culture .about-area .area-top .float-box-bottom h3,
#atomic-culture .about-area .area-top .float-box-bottom h4 {
	margin-bottom: 20px;
}
#atomic-culture .about-area .area-top .float-box-bottom h4:last-child {
	margin-top: 35px;
}
/* ./atomic culture */


/* Section dna */
#dna .section-title h2 {
	margin-bottom: 35px;
}
#dna .single-column .title-2 {
    color: #ff0066 !important;
}
/* ./dna */


/* Section disrupted world */
#disrupted-world .info h4 {
    color: rgb(255, 0, 102) !important;
    font-weight: 400 !important;
}
/* ./disrupted world */


/* Section in news */
#in-news .about-area .area-right {
    padding: 0;
}
#in-news .about-area .area-right h2 {
    margin-bottom: 40px;
}
/* ./in news */



/* Media querys */

/* Querys max */
@media only screen and (max-width: 991.98px) {
	#atomic-culture .container.culture {
	    max-width: 100vw;
	}
	#dna .section-title h2 {
		font-size: 36px !important;
		margin-bottom: 30px;
	}
	#dna .section-adn {
		padding-top: 50px !important;
	}
	#in-news .about-area .area-right h2 {
	    margin-bottom: 20px;
	}
	#dna .section-profiles .dna-profile:first-child {
		margin-top: 0 !important;
	}
	#dna .section-profiles .dna-profile:last-child {
		margin-bottom: 40px !important;
	}
	#dna .section-profiles .dna-profile {
		margin-top: 40px !important;
	}
}

@media only screen and (max-width: 767.98px) {
	#news .bg_image--31 {
	    min-height: 58vh;
	}
    #news h3 {
        font-size: 21px;
        line-height: 27px;
    }
    #news h3:nth-child(3n) {
        font-size: 16px;
        line-height: 22px;
    }
	#news h3.last-title {
		font-size: 30px !important;
	}
	#who-we-are .container-area a h4 {
		margin: 15px 0 5px !important;
	}
    .culture {
    	width: 100%; 
    	height: 100%; 
    	padding: 0; 
    	max-width: fit-content;
    }
    .thumbnail img{
        width: 100%; 
        height: 100%; 
        margin: 0; 
        padding: 0;
        border-radius: 0px !important;
    }
    #atomic-culture .area-bottom .container {
    	padding-top: 90px !important;
    }
	#atomic-culture .about-area .area-top .float-box-bottom {
	    bottom: -10.5vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h3 {
		font-size: 3.9vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h4 {
		font-size: 3.13vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h3,
	#atomic-culture .about-area .area-top .float-box-bottom h4 {
		margin-bottom: 2.7vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h4:last-child {
		margin-top: 4.8vw;
	}
	#dna .section-title h2 {
		font-size: 31px !important;
		margin-bottom: 25px;
	}
	#dna .section-profiles .single-column p.description {
	    text-align: left;
	}
	#dna .section-profiles .single-column .thumbnail img {
	    width: 150px !important;
	    border-radius: 5px !important;
	}
}

@media only screen and (max-width: 675.98px) {
    #atomic-culture .area-bottom .container {
    	padding-top: 80px !important;
    }
	#dna .section-title h2 {
		font-size: 26px !important;
	}
}

@media only screen and (max-width: 575.98px) {
	#news .container-rotating-text {
		padding-top: 16vw;
	}
    #news h3 {
        font-size: 20px;
        line-height: 25px;
    }
    #news h3:nth-child(3n) {
        font-size: 14px;
        line-height: 20px;
    }
	#news h3.last-title {
		font-size: 25px !important;
	}
	#who-we-are .about-area {
		padding-top: 30px;
	}
	#who-we-are .container-area h2,
	#who-we-are .container-area h4,
	#atomic-culture .container-area .area-bottom h4,
	#in-news .container-area h2,
	#in-news .container-area h4,
	#disrupted-world .info h4,
	#dna .content-text .single-column  {
		margin-bottom: 15px !important;
	}
	#atomic-culture .container-area .area-bottom h4.h4-list  {
		margin-bottom: 0 !important;
	}
    #atomic-culture .area-bottom .container {
    	padding-top: 70px !important;
    }
	#dna .section-title h2 {
		font-size: 24px !important;
		margin-bottom: 19px;
	}
}

@media only screen and (max-width: 500.98px) {
	#atomic-culture .about-area .area-top .float-box-bottom {
	    bottom: -7.5vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h3 {
		font-size: 4.9vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h4 {
		font-size: 4.13vw;
	}
	#atomic-culture .about-area .area-top .float-box-bottom h3,
	#atomic-culture .about-area .area-top .float-box-bottom h4 {
		margin-bottom: -1.3vw;
	}
}

@media only screen and (max-width: 479.98px) {
    #news h3 {
        font-size: 17px;
        line-height: 20px;
    }
    #news h3 span {
        line-height: 20px;
    }
    #news h3:first-child span {
        line-height: 25px;
    }
	#news .container {
		padding: 0 0 0 4px;
	}
	#news .container-rotating-text {
		padding-top: 25vw;
	}
	#who-we-are .container-area a h4 {
		font-size: 14px !important;
	}
	#atomic-culture .about-area .area-bottom .container {
		padding-bottom: 0 !important;
	}
	#dna .section-title h2 {
		font-size: 20px !important;
		margin-bottom: 15px;
	}
	#dna .section-adn {
		padding-top: 25px !important;
	}
	#in-news .about-area .area-right h2 {
	    font-size: 23px;
	}
}

@media only screen and (max-width: 675.98px) {
    #atomic-culture .area-bottom .container {
    	padding-top: 60px !important;
    }
}

@media only screen and (max-width: 428.98px) {
    #news h3 {
        line-height: 20px;
    }
	#news h3:nth-child(2n) {
        font-size: 16px;
        line-height: 15px;
	}
	#news h3:nth-child(3n) {
	    font-size: 12px;
	    line-height: 15px;
	}
	#news h3.last-title {
		font-size: 20px !important;
	}
	#dna .section-title h2 {
		font-size: 18px !important;
		margin-bottom: 13px;
	}
}

@media only screen and (max-width: 412.98px) {
	#news h3:nth-child(3n) {
	    font-size: 11px;
	    line-height: 14px;
	}
}

@media only screen and (max-width: 384.98px) {
	#news .bg_image--31 {
		min-height: 464px;
	}
	#news h3:nth-child(2n) {
	    font-size: 14px;
	    line-height: 7px;
	}
	#news h3:nth-child(3n) {
	    font-size: 11px;
	}
	#dna .section-title h2 {
		font-size: 16px !important;
		margin-bottom: 11px;
	}
}

@media only screen and (max-width: 360.98px) {
    #atomic-culture .area-bottom .container {
    	padding-top: 50px !important;
    }
}


/* Querys min */
@media only screen and (min-width: 991px) {
}

@media only screen and (min-width: 576px) {
	#news .container {
		padding: 0 0 0 4px;
	}
}