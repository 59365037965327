/* Custom file CSS */

/* Section home slide */
#home .personal-portfolio-slider .span-title.rotating-text {
	margin-left: 10px;
}
#home .personal-portfolio-slider {
	min-height: 100vh;
	background-image: url(/assets/images/bg/atomic_kitchens_home_r.png) !important;
	background-color: rgb(255, 0, 102);
	background-position: bottom center;
}
#home .personal-portfolio-slider .container-rotating-text {
	position: absolute;
	left: 0;
	background: #000;
	margin-top: 43px;
	padding: 10px 0 10px 30px;
	max-width: 670px;
}
#home .personal-portfolio-slider .container-rotating-text .inner.text-left {
	padding-top: 0;
}
#home .personal-portfolio-slider .container-rotating-text .inner.text-left h1,
#home .personal-portfolio-slider .container-rotating-text .inner.text-left h2 {
	font-family: "Poppins",sans-serif;
}
#home .personal-portfolio-slider .container-rotating-text .inner.text-left .title {
	width: auto;
}

/* Section beyond */
#beyond-dark .about-area {
	padding-top: 40px !important;
}
#beyond-dark .about-area .container-rotating-text .title-back-white {
	margin: 0;
}
#beyond-dark .about-area .area-left,
#beyond-dark .about-area .area-right {
	padding: 0 !important;
}
#beyond-dark .about-area .area-right h3, #beyond-dark .about-area .area-right h4 {
	font-weight: 400;
}
#beyond-dark .about-area .area-right h3 {
	font-size: 30px;
	white-space: nowrap;
}

/* Section disruption */
#disruption-now .container {
	padding-left: 0;
	margin-left: 0;
	max-width: 100vw;
}
#disruption-now .thumbnail-paralax {
	background-color: #101010;
	background-image: url(/assets/images/blog/kitchen_brand.jpg);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 500px;
}
#disruption-now .about-area .area-left .float-box h4 {
	font-weight: 400;
}
#disruption-now .about-area .area-left .float-box {
	background: #000;
	margin-left: 1rem;
	max-width: 200px;
	bottom: 10rem;
}
#disruption-now .about-area .area-left .float-box-bottom {
	bottom: -3.3rem;
}
#disruption-now .about-area .area-right {
	padding-bottom: 35px;
}
#disruption-now .about-area .area-right h2 {
	margin-bottom: 20px;
}
#disruption-now .about-area .area-right h3 {
	font-size: 22px;
	margin-bottom: 162px;
}
#disruption-now .about-area .container-footer {
	padding: 37px 0 45px;
	display: grid;
	justify-content: center;
}
#disruption-now .about-area .container-footer .box-footer {
	display: flex !important;
}
#disruption-now .about-area .container-footer h4 {
	font-weight: 400;
	width: max-content;
}
#disruption-now .about-area .container-footer .box-footer-left {
	margin-right: 100px;
}
#disruption-now .about-area .area-right .h4-description.title-back-white {
	font-size: 25px;
}

/* Section together */
#together-beyond .container {
	padding-top: 60px;
	max-width: 850px;
}
#together-beyond .container .title-zoom {
	font-size: 2rem;
}
#together-beyond .container .title-section {
	margin: 40px 0 20px;
}
#together-beyond .container .list-section {
    padding-left: 20px;
    padding-bottom: 15px;
}
#together-beyond .container .list-section li {
    position: relative;
}
#together-beyond .container .list-section li::before {
	content: '// ';
    position: absolute;
    top: 3px;
    /*left: -8px;*/
    left: -14px;
}

/* Section info */
#info-icon .box-icon {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 90px 95px;
}
#info-icon .container-box-icon {
    position: relative;
}
#info-icon .container-box-icon::before {
    content: url(/assets/images/logo/new_AKWeb2022-14.png);
    position: absolute;
    left: 45%;
    bottom: 42%;
    z-index: 2;
    background: #fff;
    /*padding-left: 10px;*/
}

/* Section extra income */
#extra-income .container {
	padding-left: 0;
	margin-left: 0;
}
#extra-income .thumbnail-paralax {
	background-color: #101010;
	background-image: url(/assets/images/blog/pelon_brazer.jpg);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 600px;
}
#extra-income .float-box {
    position: absolute;
    bottom: 0;
    z-index: 10;
    padding: 5px 1rem;
	background: rgb(255, 0, 102);
	font-weight: 400;
	color: #fff;
}
#extra-income .float-box h3,
#extra-income .float-box h4 {
    width: max-content;
}
#extra-income .list-section {
	padding-left: 0 !important;
}
#extra-income .adjust {
  display: flex;
  position: relative;
}
#extra-income .area-right .list-section li::before {
	content: '// ';
	position: relative;
	top: 7px;
}

/* Section the disruption */
#the-disruption .section-titles .title {
	color: rgb(255, 0, 102);
}
#the-disruption .area-left .section-title {
	padding-top: 10px;
}
#the-disruption .area-right .container-tabs li::before {
	display: none;
}
#the-disruption .area-right .container-tabs li {
	font-weight: 400;
	margin-right: 20px;
}
#the-disruption .area-right .container-tabs .tabs-area .container {
	width: auto;
}
#the-disruption .area-right .container-tabs li.react-tabs__tab--selected::before {
	color: rgb(255, 0, 102);
}
#the-disruption .area-right .container-tabs .react-tabs__tab-panel.react-tabs__tab-panel--selected {
	margin-left: -15px;
	border-radius: 4px;
	margin-top: 25px;
	padding: 25px 15px 15px;
	background: #ff0066 none repeat scroll 0% 0%;
	/*background: rgb(255, 255, 255) none repeat scroll 0% 0%;*/
}
#the-disruption .area-right .container-tabs .single-tab-content p {
	font-size: 16px;
	margin: 20px 0;
	/*color: #101010;*/
	color: #fff;
	line-height: 25px;
}
#the-disruption .ul-text {
	/*list-style: circle;*/
	margin: 16px 0;
	padding: 0;
}
#the-disruption .ul-text li {
	color: #fff;
	/*color: #101010;*/
	font-weight: 300 !important;
}


/* Section digital brands */
#digital-brands .container-img {
	background-image: url(/assets/images/bg/Digital-Brands.png) !important;
	/*background-image: url(/assets/images/bg/Digital-Brands-B.jpg) !important;*/
	background-color: #101010;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100vw 100%;
	max-width: 100vw;
	width: 100vw;
	padding: 0 0 40px;

}
#digital-brands .about-area  {
	padding-bottom: 0;
}
#digital-brands .container-title .section-titles  {
	margin-top: 0;
}
#digital-brands .container-title .title-1 {
	width: max-content;
	padding: 0 44px 0 15px;
	background: #fff;
	color: #000;
}
#digital-brands .container-title .title-2 {
	width: max-content;
	padding: 0 30px 0 15px;
	background: #FC0266;
	color: #fff;
}
#digital-brands .container-headline {
	padding-top: 70px;
	display: grid;
	justify-items: center;
}
#digital-brands .container-text {
	margin-top: 4vw;
}
#digital-brands .container-headline .title {
	padding: 0 14px;
	/*padding: 0 44px;*/
	background: #fff;
	color: #000;
}
#digital-brands .container-headline .title .span-slash {
	letter-spacing: -3px;
}
#digital-brands .container-headline .section-titles:last-child .title {
	padding: 0 44px;
	background: #FC0266;
	color: #fff;
}
#digital-brands .container-headline .section-titles h3 {
	width: max-content;
}
#digital-brands .container-headline h3 {
	font-size: 28px;
}
#digital-brands .container-headline h2 {
	font-size: 70px;
}
#digital-brands .container-headline h1 {
	font-size: 80px;
}
#digital-brands .container-headline p {
	/*
	font-size: 20px;
	padding: 15px 20px !important;
	*/
	font-size: 18px;
	padding: 10px !important;
	font-weight: 600;
	margin: 0px 10px;
	line-height: 25px;
	text-align: center;
}
#digital-brands .container-headline p:last-child {
	display: grid;
	align-items: center;
}
#digital-brands .about-area .container-text {
	margin-top: 10px;
}
#digital-brands .container-headline .section-titles:last-child p {
	/*width: 273px;*/
	width: 230px;
}
#digital-brands .container-title .h2-title {
	font-size: 40px;
}


/* Section atomic brands */
#atomic-brands #brand__title__landing {
  background-color: #fff;
  display: block;
  max-width: max-content;
  width: max-content;
  color: #000;
  margin-bottom: 2vw;
  padding: 0 15px;
}
#atomic-brands .beyond__digital__brand {
  height: 4rem;
  position: relative;
  border: 1px solid #fff;
}
#atomic-brands .dark__digital__brand {
  background-color: #101010;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%);
  width: 85%;
  padding: 0 2rem;
}
#atomic-brands .content-extra-capacity {
	padding-top: 8vw;
}
#atomic-brands .beyond .dark {
  background: #101010;
}
#atomic-brands .beyond .dark h3 {
  font-size: 30px;
}
#atomic-brands .beyond-description {
  margin-top: 1rem;
}
#atomic-brands .container-text .title-back-white {
	color: #000 !important;
}
#atomic-brands .content-text .h2-title {
	font-size: 40px;
}

/* Section contact */
#contact {
	background-image: url(/assets/images/bg/contact_login_bg.jpg) !important;
	background-color: #101010;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100vw 100%;
}
#contact .section-head-office {
	padding-left: 15px;
	padding-top: 30px;
	padding-bottom: 10px;
	display: grid;
}
#contact .section-head-office a {
	color: #fff;
	transition: 0.3s;
}
#contact .section-head-office a:hover {
	color: #f9004d;
}
#contact .section-head-office p {
	color: #fff;
	font-size: 16px;
}
#contact .section-head-office p .h3-subtitle {
	padding-bottom: 10px;
	font-weight: normal;
}
#contact .section-head-office p em {
	font-size: 16px;
}


/* Media querys */

/* Querys max */
@media(max-width: 991.98px) {
	#beyond-dark .about-area {
		padding-bottom: 50px !important;
	}
	#beyond-dark .about-area .area-right {
		margin-top: 30px;
	}
	#home .personal-portfolio-slider .container-rotating-text {
		max-width: 620px;
	}
	#disruption-now {
		background-color: rgb(255, 0, 102);
	}
	#disruption-now .thumbnail-paralax {
		background-color: rgb(255, 0, 102);
	}
	#disruption-now .thumbnail-paralax {
		width: 100vw;
		height: 950px;
	}
	#disruption-now .about-area .container-area {
		justify-items: center;
	}
	#disruption-now .about-area .area-right {
		padding-top: 0 !important;
	}
	#disruption-now .about-area .area-right h2 {
		padding-right: 50px;
		background: #fff;
		font-weight: 400;
		color: #ff0066 !important;
		padding: 2px 30px 2px 10px;
		width: max-content;
	}
	#disruption-now .about-area .area-right h3 {
		margin-bottom: 20px;
	}
	#disruption-now .about-area .area-right .h4-description.title-back-white {
		display: none;
	}
	#disruption-now .about-area .container .container-area {
		display: grid;
	}
	#disruption-now .about-area .container .area-left {
		order: 2;
	}
	#disruption-now .about-area .container .area-right {
		order: 1;
		width: 100vw;
		max-width: 90vw;
		padding-bottom: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	#disruption-now .about-area .container-footer {
		width: 100vw;
		margin-left: auto !important;
		padding-top: 6rem !important;
	}
	#disruption-now .about-area .area-left .float-box-bottom h4 {
		font-size: 25px !important;
		margin-bottom: 7px;
	}
	#disruption-now .about-area .container-footer .box-footer-left {
	    margin-right: 8vw;
	}
	#disruption-now .about-area .area-left .float-box-bottom {
		bottom: -4.5rem;
	}
	#info-icon .container-box-icon::before {
	    left: 43%;
	    /*left: 45%;*/
	}
	#extra-income .container {
		max-width: 100vw;
		width: 100vw;
		padding: 0;
	}
	#extra-income .container .area-right {
		max-width: 90vw;
		margin: auto;
		padding-left: 15px !important;
	}
	#extra-income .adjust::before {
	    position: absolute;
	    left: -6px;
	    top: 7px;
	}
	#extra-income .thumbnail-paralax {
		width: 100vw;
		height: 950px;
	}
	#extra-income .float-box {
		width: max-content !important;
		bottom: 0;
	}
	#extra-income .area-right {
		padding-left: 3rem !important;
		padding-top: 40px !important;
	}
	#the-disruption .about-area {
		padding-top: 20px !important;
	}
	#the-disruption .area-right .container-tabs ul {
		display: grid;
	}
	#the-disruption .about-area .container .area-right .about-inner {
		padding-top: 30px !important;
	}
	#digital-brands .container-headline {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	#digital-brands .container-headline h3 {
		font-size: 20px;
	}
	#digital-brands .container-headline h2 {
		font-size: 50px;
	}
	#digital-brands .container-headline h1 {
		font-size: 60px;
	}
	#digital-brands .container-headline p {
		line-height: 25px;
	}
	#atomic-brands .content-text {
		margin-left: -5vw !important;
	}
	#atomic-brands .content-text .h2-title {
		padding-left: 6.5vw !important;
	}
	#atomic-brands .about-area {
		padding-bottom: 30px !important;
	}
	#atomic-brands .container-text .container {
		padding: 0;
	}
	#contact {
		background: unset !important;
	}
	#contact .order-1 {
		display: none;
	}
	#contact .rn-contact-area .container  {
		max-width: 100vw;
		padding: 0;
	}
	#contact .rn-contact-area {
		padding-top: 0 !important;
		background: #1a1a1a;
	}
	#contact .contact-form--1 label {
		background: #1a1a1a !important;
	}
	#contact .rn-contact-area .container {
		padding: 0px !important;
		width: 100%;
	}
	#contact .rn-contact-area .container .order-1 {
		text-align: center;
		padding-bottom: 20px !important;
	}
	#contact .box-text {
		background-image: url(/assets/images/bg/contact_login_bg.jpg) !important;
		background-color: #101010;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100vw 100%;
		min-height: 225px;
		/*min-height: 263px;*/
		padding: 20px 6vw 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	#contact .box-form {
		padding: 20px 6vw 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	#contact .container {
		padding: 0;
		margin: auto;
		max-width: 100vw;
	}
	#contact .row .align-items-center {
		margin: auto !important;
		width: 100vw;
	}
	#contact .box-form .section-head-office {
		padding: 20px 10px !important;
		margin: 50px 0 0 !important;
		border: 1px solid #fff !important;
	}
	#contact .box-form .section-head-office p .h3-subtitle {
		font-size: 15px !important;
	}
	#contact .box-form .section-head-office p em {
		font-size: 12px !important;
	}
}

@media(max-width: 767.98px) {
	#home .personal-portfolio-slider {
		min-height: 500px;
	}
	#home .personal-portfolio-slider .container-rotating-text {
		bottom: 135px;
		max-width: 315px;
	}
	#beyond-dark .about-area {
		padding-top: 30px !important;
	}
	#disruption-now .thumbnail-paralax {
		height: 750px;
	}
	/*
	#disruption-now .about-area .container-footer .box-footer-left {
		flex: 0 0 35%;
		max-width: 35%;
	}
	#disruption-now .about-area .container-footer .box-footer-right {
		flex: 0 0 61.666667%;
		max-width: 61.666667%;
	}
	*/
	#disruption-now .about-area .container-footer .box-footer-right .h4-description {
		white-space: normal;
		width: 100%;
	}
	#together-beyond .container .beyond {
		height: 4.5rem;
	}
	#together-beyond .container .dark {
		bottom: 30%;
	}
	#together-beyond .container .title-dark {
		font-size: 20px;
	}
	#together-beyond .container .title-zoom {
		font-size: 25px;
	}
	#info-icon .box-icon {
		padding: 50px 55px;
	}
	#info-icon .container-box-icon::before {
	    left: 42%;
	    /*left: 43%;*/
	}
	#extra-income .thumbnail-paralax {
		height: 750px;
	}
	#digital-brands .section-titles {
		margin-bottom: 15px !important;
	}
	#digital-brands .container-headline h3 {
		margin-bottom: 20px;
	}
	#digital-brands .container-title .title-2 {
		padding: 0 37px 0 15px;
	}
	#digital-brands .container-headline .section-titles:last-child p {
	    width: 227px;
	}
	#digital-brands .container-headline p {
		line-height: 22px !important;
	}
}

@media(max-width: 675.98px) {
	#digital-brands .container-headline h2 {
		font-size: 40px;
	}
	#digital-brands .container-headline h1 {
		font-size: 50px;
	}

	.power-kitchens p {
		font-size: 14px !important;
		margin-left: -15px;
		margin-right: 10px;
		word-break: break-word;
	}
}

@media(max-width: 575.98px) {
	#home .personal-portfolio-slider .container-rotating-text {
		max-width: 310px;
	}
	#beyond-dark .about-area h3 {
		font-size: 20px;
	}
	#disruption-now .about-area .container .area-right {
		max-width: 95vw;
	}
	#disruption-now.thumbnail-paralax {
		height: 650px;
	}
	#disruption-now .about-area .area-right h2 {
		padding-right: 15px;
	}
	#disruption-now .about-area .container-footer {
	    padding-top: 5rem !important;
	    padding-bottom: 0 !important;
	}
	#disruption-now .about-area .area-left .float-box-bottom h4 {
		font-size: 22px !important;
		line-height: 30px !important;
	}
	#disruption-now .about-area .area-left .float-box-bottom {
	    bottom: -4.2rem;
	}
	#disruption-now .about-area .area-right h4.block-mobile {
		display: grid;
	}
	#together-beyond .container .title-zoom {
		font-size: 22px;
	}
	#together-beyond .container .dark {
	    bottom: 40%;
	}
	#together-beyond .container .container-beyond .beyond {
	    height: 4.5rem;
	}
	#together-beyond .container .list-section li::before {
	    top: 0px;
	}
	#info-icon .box-icon {
		padding: 45px 40px;
	}
	#info-icon .box-icon h4 {
		font-size: 15px;
	}
	#info-icon .info-icon-footer h3 {
		font-size: 20px;
	}
	#info-icon .container-box-icon::before {
	    left: 39%;
	    /*left: 41%;*/
	}
	#extra-income .thumbnail-paralax {
		height: 650px;
	}
	#extra-income .area-right .list-section li::before {
		top: 6px;
	}
	#the-disruption .about-area {
		padding-top: 0px !important;
	}
	#digital-brands .container-headline {
		padding-top: 40px !important;
	}
	#digital-brands .container-headline h2 {
		font-size: 35px;
	}
	#digital-brands .container-headline h1 {
		font-size: 40px;
		margin: 10px 0;
	}
	#digital-brands .container-headline p {
		padding: 7px 10px !important;
		line-height: 22px !important;
	}
	#digital-brands .container-headline .section-titles:last-child {
		justify-content: space-between;
	}
	#digital-brands .container-headline .section-titles p {
	    max-width: 220px;
	}
	#atomic-brands .content-text .h2-title {
		padding-left: 7.5vw !important;
	}
	#atomic-brands .beyond .dark h3 {
	    font-size: 25px;
	}
	#the-disruption .about-area .container .area-right .about-inner .container-tabs {
		margin-top: 20px !important;
	}
}

@media(max-width: 575.98px) and (min-width: 480px) {
	#together-beyond .container .container-beyond {
		display: grid;
		justify-items: center;
	}
	#together-beyond .container .container-beyond .beyond {
		width: 90%;
	}
}

@media(max-width: 500.98px) {
	#together-beyond .container .beyond {
		height: 3.5rem !important;
	}
	#together-beyond .container .dark {
	    bottom: 35%;
	}
	#together-beyond .container h3 {
		font-size: 16px;
	}
	#together-beyond .container .title-zoom {
		font-size: 20px;
	}
	#together-beyond .container .list-section li::before {
	    top: -1px;
	}
	#digital-brands .container-headline h2 {
		font-size: 30px;
	}
	#digital-brands .container-headline h1 {
		font-size: 37px;
	}
	#digital-brands .container-headline p {
		font-size: 12px !important;
		line-height: 20px !important;
	}
	#digital-brands .container-headline .section-titles p {
	    max-width: 160px;
	}
	#extra-income .adjust::before {
	    top: 5px;
	}
}

@media(max-width: 479.98px) {
	#home .personal-portfolio-slider .container-rotating-text {
		max-width: 265px;
		padding-left: 20px;
	}
	#home .personal-portfolio-slider .container-rotating-text .span-title {
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
	}
	#beyond-dark .about-area h3 {
		font-size: 16px;
	}
	#beyond-dark .about-area .area-right h2 {
		font-size: 36px;
	}
	#disruption-now .thumbnail-paralax {
		height: 550px;
	}
	#disruption-now .about-area .area-right h2 {
		font-size: 23px;
	}
	#disruption-now .about-area .area-right h3 {
		font-size: 18px;
	}
	#disruption-now .about-area .area-right h4 {
		font-size: 16px;
	}
	#together-beyond .container {
	    padding-top: 50px;
	}
	#together-beyond .container .title-dark {
		font-size: 16px;
	}
	#together-beyond .container .title-zoom {
		font-size: 17px;
	}
	#info-icon .container-box-icon::before {
	    /*left: 39%;*/
	    left: 35%;
	}
	#info-icon .info-icon-footer h3 {
		font-size: 18px;
		/*width: max-content;*/
		padding-left: 35px !important;
	}
	#extra-income .thumbnail-paralax {
		height: 550px;
	}
	#extra-income .area-right .list-section li::before {
		top: 4px;
	}
	#atomic-brands .content-text .h2-title {
		padding-left: 8.5vw !important;
	}
	#atomic-brands .beyond {
	    height: 4.5rem;
	}
	#atomic-brands .beyond .dark h3 {
	    font-size: 22px;
	}
	#atomic-brands .beyond .dark {
	    bottom: 45%;
	    padding: 0px 15px;
	}
	#atomic-brands .content-text .h2-title {
		/*font-size: 25px !important;*/
		font-size: 20px !important;
	}
}

@media(max-width: 428.98px) {
	#disruption-now .thumbnail-paralax {
		height: 450px;
	}
	#disruption-now .about-area .area-left .float-box {
		bottom: 7rem;
	}
	#disruption-now .about-area .container-footer .box-footer-left {
	    margin-right: 5vw;
	}
	#disruption-now .about-area .area-right h2 {
	    padding-right: 8px;
	}
	#together-beyond .container .dark {
		bottom: 40%;
	}
	#together-beyond .container .title-dark {
		font-size: 14px;
	}
	#together-beyond .container .title-zoom {
		font-size: 15px;
	}
	#extra-income .thumbnail-paralax {
		height: 450px;
	}
	#digital-brands .container-headline .section-titles {
		justify-content: center;
	}
	#digital-brands .container-headline h2 {
		font-size: 26px;
	}
	#atomic-brands .beyond {
	    height: 3.5rem;
	}
	#atomic-brands .beyond .dark h3 {
	    font-size: 18px;
	}
	#atomic-brands .beyond .dark {
	    bottom: 39%;
	    padding: 0px 11px;
	}
	#atomic-brands .about-area {
		padding-bottom: 30px !important;
	}
	#contact .rn-contact-area {
	    padding-top: 0px !important;
	}
}

@media only screen and (max-width: 412.98px) {
	.footer-style-2 .inner p {
		font-size: 12px !important;
	}
}

@media(max-width: 384.98px) {
	#disruption-now .about-area .area-left .float-box {
		max-width: 155px;
	}
	#disruption-now .about-area .area-left .float-box h4,
	#disruption-now .about-area .area-left .float-box-bottom h4 {
		font-size: 15px;
	}
	#disruption-now .about-area .container-footer {
	    width: 96vw;
	}
	#info-icon .container-box-icon::before {
	    left: 33%;
	    /*left: 37%;*/
	}
	#digital-brands .container-headline {
		margin-left: -15px;
	}
	#digital-brands .container-headline h3 {
		margin: 0;
	}
	#digital-brands .container-headline h2 {
		font-size: 22px;
	}
	#digital-brands .container-headline h1 {
		font-size: 30px;
	}
	#extra-income .float-box {
	    padding-top: 5px;
	    padding-bottom: 5px;
	}
	#digital-brands .container-headline {
	    margin-left: -10px;
	    width: 100vw;
	}
	#digital-brands .container-headline .section-titles {
	    justify-content: center !important;
	}
	#digital-brands .container-headline .section-titles p {
	    max-width: 160px;
	}
	#digital-brands .container-headline .section-titles p:last-child {
		display: grid;
		align-items: center;
	}
	#atomic-brands .content-text .h2-title {
		font-size: 20px !important;
	}
}

@media(max-width: 360.98px) {
	#disruption-now .thumbnail-paralax {
		height: 350px;
	}
	#disruption-now .about-area .container-footer h4 {
		font-size: 15px;
	}
	#disruption-now .about-area .container-footer .box-footer-left {
		padding-right: 0 !important;
	}
	#disruption-now .about-area .container-footer .box-footer-right {
		padding-left: 0 !important;
	}
	#extra-income .thumbnail-paralax {
		height: 350px;
	}
}



/* Querys min */
@media(min-width: 992px) {
	header .mainmenunav .mainmenu li.li-logo {
		display: none !important;
	}
	#disruption-now .about-area .container-footer {
		padding-top: 0;
	}
	#disruption-now .about-area .area-left .float-box-bottom {
		display: none;
	}
	#disruption-now .about-area .area-right h2 {
		font-size: 42px;
	}
	#disruption-now .about-area .area-left .float-box {
		/*bottom: 7rem;*/
		bottom: 16rem;
	}
	#disruption-now .about-area .area-left .float-box-bottom {
		bottom: -4.3rem;
	}
	#contact .box-form .section-head-office {
		display: none;
	}
}

@media(min-width: 768px) {
	#together-beyond .container .dark {
		bottom: 20%;
	}
	#info-icon .container-box-icon h4 {
	    text-align: center;
	}
}