/*===================
    Sections 
====================*/

.page-wrapper.page-team {
    color: rgba(198,201,216,0.75);
    .bg_color--5 {
        background: #101010;
    }
    .bg_color--1 {
        background: #191919;
    }
    .rn-columns-area {
        .single-column {
            align-items: center;
            text-align: center;
            color: #fff;
            &.single-intro {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                p {
                    font-size: 20px;
                    width: 80%;
                }
                @media #{$sm-layout} {
                    display: unset;
                    justify-content: unset;
                    p {
                        width: 100%;
                    }
                }
            }
            .section-title {
                .title {
                    color: #fff !important;
                }
            }
            .thumbnail {
                margin-bottom: 20px;
            }
            .title {
                color: #fff;
            }
            .title-2 {
                color: #f9004d;
                font-weight: bold;
            }
        }
        .rn-brand-area.brand-separation {
            h2, h3 {
                color: #fff;
            }
            p {
                color: rgba(198,201,216,0.75);
            }
        }
    }
}

.section-service {
    &.section-service-home {
        .rn-brand-area.brand-separation {
            padding-bottom: 188px;
        }
    }
    .rn-brand-area.brand-separation {
        .service-one-wrapper {
            a {
                cursor: default;
            }
            .service {
                &:hover {
                    p {
                       color: #fff !important;
                    }
                }
            }
        }
        .container {
            width: 100%;
            padding: 0;
        }
        @media #{$laptop-device} {
            .container {
                width: auto;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
}

.section-virtual {
    .row-service {
        a {
            cursor: default;
        }
        .service {
            @media #{$md-layout} {
                width: 100%;
            }
        }
    }
}

.section-time {
    .blog {
        a {
            cursor: default;
        }
    }
}

.section-adn {
    .section-title {
        .title {
            display: grid;
        }
    }
    @media #{$laptop-device} {
        .section-title {
            .title {
                display: unset;
            }
        }
    }
}

.section-first {
    .section-title {
        .title {
            display: grid;
            color: #fff;
        }
    }
    ul {
        &.brand-style-2 {
            li {
                flex-basis: 66%;
                img {
                    opacity: 1;
                }
            }
        }
    }
    @media #{$laptop-device} {
        .section-title {
            .title {
                display: unset;
            }
        }
        ul {
            &.brand-style-2 {
                li {
                    flex-basis: 30%;
                }
            }
        }
    }
}