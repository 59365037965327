/* Custom file invstors CSS */

/* Section investors slide */
#investors .slider-paralax .container {
	max-width: 100vw !important;
	width: 100vw !important;
}
#investors .slider-paralax {
	min-height: 100vh;
	background-image: url(/assets/images/about/bussinesman_atomic.jpg) !important;
	background-color: rgb(255, 0, 102);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100vw 100%;
}
#investors .slider-paralax .container-text {
	position: absolute;
	left: 0;
	margin-top: 76px;
	padding: 10px 0;
}
.investor-title {
  background-color: #fff;
  display: block;
  width: max-content;
  color: #f06 !important;
  margin: 0.5rem 0;
  padding: 5px 20px 5px 5vw;
}
#investors .slider-paralax .container-text h3:first-child {
	padding-right: 35px;
}
/* ./investors slide */

/* Section login form */
#login .form-control {
  display: block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
#login .form-control:focus {
  color: #181818;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #f06;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#login .form-control::placeholder {
  /*color: #181818;*/
  opacity: 1; 
}
#login .sigin {
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 2.25rem;
}
#login .sigin:hover {
  background-color: rgba(255, 0, 102, 0.85);
}
#login .form-group:last-child {
  margin-bottom: 0 !important;
}
/* ./login */



/* Media querys */

/* Querys max */
@media screen and (max-width: 991.98px) {
	#investors .slider-paralax {
		min-height: 600px;
	}
}

@media screen and (max-width: 767.98px) {
	#investors .slider-paralax {
		min-height: 500px;
	}
	#investors .slider-paralax .container-text {
		top: -9vw;
	}
	#login .about-area {
	  padding: 50px 0 !important;
	}
}

@media screen and (max-width: 575.98px) {
	#investors .slider-paralax {
		min-height: 400px;
	}
	.investor-title {
		font-size: 20px;
		padding: 3px 20px 3px 5vw;
	}
	#login .about-area {
	  padding: 40px 0 !important;
	}
}

@media screen and (max-width: 479.98px) {
	.investor-title {
		font-size: 18px;
		padding: 0 15px 0 5vw;
	}
	#investors .slider-paralax .container-text h3:first-child {
	    padding-right: 22px;
	}
}

@media screen and (max-width: 384.98px) {
	.investor-title {
		font-size: 17px;
		padding: 0 13px 0 5vw;
	}
	#investors .slider-paralax .container-text h3:first-child {
	    padding-right: 19px;
	}
}

@media screen and (max-width: 480px) {
  .dark {
    width: 75%;
    bottom: 70%;
  }

  .dark > h3 {
    font-size: 16px;
  }
}