/* Custom file global CSS */

.container {
    max-width: 90vw;
    width: 100vw;
}

.w-10 {
  width: 10%;
}

.w-10 {
  width: 10%;
}

.hidden {
  display: none !important;
}

ul.underscore {
  list-style: none;
}

li::before {
  content: '_ ';
  color: #fff;
}

.title-back-white {
	background: #fff;
	font-weight: 400;
	color: #ff0066 !important;
	padding: 2px 10px;
	margin: 10px 0;
	width: max-content;
}

.color-atomic {
  color: #ff0066 !important;
}

.bg-atomic {
  background-color: #ff0066;
}

.border-l-atomic {
  border-left: 1px solid #ff0066;
}

.border-r-atomic {
  border-right: 1px solid #ff0066;
}

.border-b-atomic {
  border-bottom: 1px solid #ff0066;
}

.border-t-atomic {
  border-top: 1px solid #ff0066;
}

.text-xl {
  font-size: 3rem;
}

.beyond {
  border: 1px solid #fff;
  position: relative;
  height: 5.5rem;
}

.beyond .dark {
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%);
  background: #f06;
  width: max-content;
  padding: 0;
  /*padding: 0 23px;*/
}

/* Section header */
header .mainmenunav .mainmenu li::before {
	/* content: '// '; */
	display: none;
}
header .mainmenunav .mainmenu li svg.svg {
	width: 15.5px;
    height: 15.5px;
}
header .mainmenunav .mainmenu li svg.svg path {
	fill: #fff;
	stroke:#fff;
	transition: 0.3s;
}
header .mainmenunav .mainmenu li a:hover svg.svg path {
	fill: #f9004d;
	stroke:#f9004d;
}
header .header-right .content-lang-menu .dropdown.nav-item {
	position: relative !important;
}
header .header-right .content-lang-menu .dropdown.nav-item #nav-dropdown {
	color: #fff;
	padding-right: 0;
}
header .header-flags .header-center {
	width: 100%;
	/*padding-top: 20px;*/
}
header .header-flags .row-flags .col-flag {
	margin-bottom: 10px;
}
header .header-flags .row-flags .col-flag img {
	max-width: 40px;
	height: auto;
	-webkit-filter: grayscale(1);
	filter: gray;
	filter: grayscale(1);
	border: 1px solid #8c8c8c;
}
header .header-flags .row-flags .col-flag p {
	line-height: 20px;
}
header .header-flags .row-flags .col-flag em {
	color: #fff;
	font-size: 12px;
}
.slide-primary-header {
	margin-top: 45px;
}

/* Latam landing */
#home.latam-landing .personal-portfolio-slider {
	background-image: url(/assets/images/bg/atomic_kitchens_home.jpg) !important;
}
/* Fin Latam */

/* Latam tipo Flyer */
#latam {
	width: 100%;
}
#latam .flyer {
	display: flex;
	justify-content: center;
}
#latam .iconDownload svg {
	margin-right: 35px;
	margin-top: 25px;
	float: right;
	fill: white;
}
#latam .iconDownload a:hover svg path {
	fill: #ff0066;
}
/* Fin Latam */


/* Media querys globals */
/* Querys max */
@media(max-width: 991.98px) {
	#latam .pdf-des {
		display: none;
	}
	header .header-flags .row-flags .col-flag {
		margin-bottom: 0 !important;
	}
	header .header-flags .row-flags .col-flag p {
		line-height: 18px !important;
	}
	header .mainmenu li {
		display: flex;
	}
	header .mainmenu li::before {
		margin-right: 5px;
	}
	header .mainmenu li.li-dropdown-lang {
		display: none !important;
	}
}
@media(max-width: 767.98px) {
	.h2-title {
		font-size: 40px !important;
	}
	.h3-subtitle {
		font-size: 22px !important;
	}
	.h4-description {
		font-size: 19px !important;
	}
	.about-wrapper-img {
		padding: 0 !important;
	}
	.about-wrapper-img .container {
		max-width: 100vw;
		padding: 0 !important;
	}
	.about-wrapper-imgr .container .thumbnail img {
		max-width: 100% !important;
	}
	.footer-container .row-cols .col-content {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
}

@media(max-width: 575.98px) {
	.container {
	    max-width: 95vw;
	}
	.h2-title {
		font-size: 30px !important;
	}
	.h4-description {
		font-size: 18px !important;
	}
	header.header-lading .header-right.header-flags {
		display: none;
	}
}

@media(max-width: 500.98px) {
	.h4-description {
		font-size: 16px !important;
	}
}

@media(max-width: 479.98px) {
	.h2-title {
		font-size: 30px !important;
	}
	.h3-subtitle {
		font-size: 20px !important;
	}
	header .header-flags .row-flags .col-flag img {
		max-width: 30px;
		height: auto;
	}
	header .header-flags .row-flags .col-flag p {
		line-height: 14px !important;
	}
	header .header-flags .row-flags .col-flag em {
		font-size: 10px !important;
	}
}

@media(max-width: 428.98px) {
	.h3-subtitle {
		font-size: 18px !important;
	}
}

@media(max-width: 384.98px) {
	.h3-subtitle {
	    font-size: 16px !important;
	}
	.h4-description {
	    font-size: 14px !important;
	    line-height: 22px !important;
	}
	header .header-flags .row-flags .col-flag p {
		line-height: 8px !important;
	}
	header .header-flags .row-flags .col-flag em {
		font-size: 8px !important;
	}
}

/* Querys min */
@media(min-width: 992px) {
	.container {
	    max-width: 85vw;
	}
	.container-beyond {
		max-width: 80%;
	}
	#latam .pdf-mob {
		display: none;
	}
	header .header-right .content-lang-menu {
		display: none;
	}
}

@media(min-width: 576px) {
	header.header-lading .header-wrapper.header-flags {
		display: none;
	}
}